import React, { useEffect, useState } from "react";
import App from "./app";
import { SEOMetaTag } from "../components/seoMetaTag";
import { dashboardPropertyDetail } from "../components/Api/ListingApi";
import { useLocation } from '@reach/router';
import * as amplitude from '@amplitude/analytics-browser';

export default function Index() {
  const amplitutekey = process.env.AMPLITUTE_ANALYTICS_KEY;
  amplitude.init(amplitutekey);


  return (
    <>
      <section>
        <App />
      </section>
    </>
  )
}
export const Head = (props) => {
  const location = useLocation().pathname.split("/")[2]
  // const location = "735-Woodside-DR-WOODSIDE-CA-94062"
  
  const url = useLocation().href
  // console.log("location", location)
  // console.log("data data data",url)
  const [propertyDetails, setPropertDetails] = useState({})

  useEffect(() => {
    // if (window.location.pathname == "/homedetails") {
      PropertyDetailsPage()
    // }

  }, [])

  const PropertyDetailsPage = async () => {
    let userId = localStorage.getItem("userId")
    let getParams = {
      user_id: userId
    }
    await dashboardPropertyDetail(location, getParams).then((res) => {
      // console.log("response", res.data.data)
      setPropertDetails(res.data.data)
      console.log("res.data.data checking",res.data.data)
    }).catch((error) => {
      console.log(error)
    })
  }
  // console.log("propertyDetails.address",propertyDetails.address)
  return (
    <SEOMetaTag title={propertyDetails?.address ? propertyDetails.address : ""} description={propertyDetails?.description ? propertyDetails.description:""} image={propertyDetails?.thumbnail_image ? propertyDetails.thumbnail_image : ""} url={url} />
  )
}


