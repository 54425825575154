import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"


export const SEOMetaTag = ({ title, description, image, url }) => {
  const { title: dtitle, description: ddescription, image: dimage, url: durl } = useSiteMetadata()
  const seo = {
    title: title || dtitle,
    description: description || ddescription,
    image: image || dimage,
    url: "https://www.gokeywe.com" || durl,
  }
  
  return (
    <>
      <title>{title}</title>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@gokeyweDotCom" />
      <meta name="twitter:site" content="@gokeyweDotCom" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:image:alt" content={seo.title} />
      <meta property="og:site_name" content="https://gokeywe.com/" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="twitter:description" content={seo.description} />
    </>

  )
}